import {Link, useParams} from "react-router-dom";

import "../sass/components/thankyou.scss"
import {useDispatch} from "react-redux";
import {ReactSession} from "react-client-session";
import {fetchCustomer, fetchPaymentVintner} from "../apiRequests/api";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";

export default function Thankyou () {
    const {orderId} = useParams()
    const dispatch = useDispatch()
    const [isErrorM, setIsErrorM] = useState(false)
    const [Message, setMessage] = useState('')
    const {getIdTokenClaims} = useAuth0()

    async function loadCustomer() {
        const token = await getIdTokenClaims()
        return await fetchCustomer(token.__raw)
    }

    async function manageVintnerPayment() {
        dispatch({type: "UPDATE_CART", payload: []})

        const pi_id = ReactSession.get('pi_id')

        const token = await getIdTokenClaims()
        let fetchParameters = {'pi_id' : pi_id}
        fetchParameters.account = await loadCustomer()

        const data = await fetchPaymentVintner(fetchParameters, token.__raw)

        switch(data.mainResult) {
            case -1:
                // Customer Account does not exist
                setIsErrorM(true)
                setMessage("Failed to save payment. Vintner customer account does not exist.")
                break
            case -2:
                // Customer Account is a sub account
                setIsErrorM(true)
                setMessage("Failed to save payment. Vintner customer account is a sub account.")
                break
            case -3:
                // Payment intent has no charge
                setIsErrorM(true)
                setMessage("Failed to save payment. The Stripe payment has no charge.")
        }

        ReactSession.remove('pi_id')
    }

    useEffect(() => {
        manageVintnerPayment()
    }, [])
    return (
        <div className="page thank-you-page">
            <h2>Thank you for your order</h2>
            <p>Thank you for your order. Your order number is: <Link to={`/order-details/${orderId}`} className={"order-number"}>{orderId}</Link>
                <br/>
                You should soon receive a confirmation email with details of your order.</p>
        </div>
    )
}