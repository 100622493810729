import React, {useState} from "react";
import {updateCart} from "../utils/cartTools";
import {useDispatch, useSelector} from "react-redux";
import closeIcon from "../assets/close-purple.svg";
import {Link} from "react-router-dom";
import wineWhite from "../assets/Wine-white.png";
import wineRed from "../assets/Wine-red.png";
import wineWhiteGrey from "../assets/Wine-white-grey.png";
import {productFields} from "../settings";

export default function BasketItem (props) {
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    const [cases,setCases] = useState(props.el.CasesQty)
    const [bottles,setBottles] = useState(props.el.BottlesQty)
    var StockTBottles = +props.el.StockCases * +props.el.CaseSize + +props.el.StockBottles
    const [updateClass, setUpdateClass] = useState('')
    // logic for add to cart button
    function addToCartHandler () {
        let currentProduct = props.el
        if(cases > 0 || bottles > 0){
            let rest = bottles%currentProduct.CaseSize
            let newCases = +cases //+ ((bottles-rest)/currentProduct.CaseSize)
            let newBottles = +bottles//rest

            if(newBottles > StockTBottles) {
                setCases(newCases)
                setBottles(StockTBottles)
                newBottles = StockTBottles
            } else {
                setCases(newCases)
                setBottles(newBottles)
            }

            setCases(newCases)
            setBottles(newBottles)
            const newCart = updateCart(currentProduct, cart, 'ADD', newCases, newBottles)
            dispatch({type: "UPDATE_CART", payload: newCart})
        }
        setUpdateClass('refreshed')
        setTimeout(function(){ setUpdateClass('') }, 500);
    }
    function removeFromCartHandler () {
        let product = props.el
        const newCart = updateCart(product, cart, "REMOVE")
        dispatch({type: "UPDATE_CART", payload: newCart})

    }

    // Get correct image for product.
    var image = props.el.Image1_URL !== "" ? process.env.REACT_APP_URL_TO_IMAGES+props.el.Image1_URL
        : (props.el.Type === "White") ? wineWhite
            : (props.el.Type === "Red") ? wineRed : wineWhiteGrey;

    // Set the UnitSize depending on Flag

    if (process.env.REACT_APP_UNIT_FLAG !== "1") {
        var unit = props.el.CaseSize;
    } else {
        var unit = "1";
    }

    console.log(props.el)
    return (
        <div className="basket-list-item_wrapper">
            <div className="basket-list-item_left">
                <Link to={`/product-detail/${props.el.Code}`}>
                    <img
                        src={image}
                        alt="Profile"
                        className="nav-user-profile rounded-circle"
                    />
                </Link>
            </div>
            <div className="basket-list-item_right">
                {props.el.Description !== "" &&
                    <div className='basket-list-item-title-link-wrapper'>
                        <Link className='basket-list-item-title-link' to={`/product-detail/${props.el.Code}`}><h2 className='basket-list-item-title'>{props.el.Description}</h2></Link>
                           <img className="cart-remove-icon" src={closeIcon} alt="cart-remove-icon" onClick={() => removeFromCartHandler()}/>
                    </div>
                }
                <div className="basket-list-item_right__top">
                    {productFields.map((el, index)=> el.name && !el.combined ? (
                            <div key={index} className="basket-list-item_inner">
                                <i className={`fas fa-icon ${el.iconClass}`}></i>
                                <p>{props.el[el.name]}</p>
                            </div>
                        ):(
                        <div key={index} className="basket-list-item_inner">
                            <i className={`fas fa-icon ${el.iconClass}`}></i>
                            <p>
                            {el.combined.map((combinedEl,index)=> (
                                <span key={index}>{props.el[combinedEl.name]}{index < el.combined.length-1 && el.separator}</span>
                                ))}
                            </p>
                        </div>
                        )
                    )}
                </div>

                <div className="basket-list-item_right__bottom">
                    <div className="basket-list-item_right__bottom__left">

                        {process.env.REACT_APP_UNIT_FLAG !== "1" &&
                        <div className="basket-list-item_inner case-nett">
                            <h6 className='basket-list-item-label'>Case Nett:</h6>
                            <p>{window.currency}{props.el.CasePrice}</p>
                        </div>
                        }

                        {process.env.REACT_APP_UNIT_FLAG === "1" &&
                        <div className="basket-list-item_inner bottle-nett">
                            <h6 className='basket-list-item-label'>Bottle Nett:</h6>
                            <p>{window.currency}{(Math.round((props.el.BottleNett * 1000)/10)/100).toFixed(2)}</p>
                        </div>
                        }

                        {process.env.REACT_APP_UNIT_FLAG !== "1" &&
                        <div className="basket-list-item_inner input">
                            <h6 className='basket-list-item-label cases'>Cases</h6>
                            <label className="basket-list-item-input-label" htmlFor="stock-cases-input">(<b>Stock: </b>{props.el.StockCases})</label>
                            <div className='product-list-item__qty-row'>
                                <input type="button" value="-" className="button-minus no_print"
                                       onClick={()=>setCases(cases>0&&cases-1)} data-field="quantity"
                                />
                                <input type="number" id="stock-cases-input" name="stock-cases-input" min="0" value={cases} placeholder={'0'}
                                       onChange={(e) => setCases(+e.target.value<0?'':+e.target.value)}
                                />
                                <input type="button" value="+" className="button-plus no_print"
                                       onClick={()=>setCases(+cases+1)} data-field="quantity"
                                />
                            </div>
                        </div>
                        }
                        {process.env.REACT_APP_UNIT_FLAG !== "2" &&
                        <div className="basket-list-item_inner input">
                            <h6 className='basket-list-item-label bottles'>Bottles</h6>
                            <label className="basket-list-item-input-label" htmlFor="stock-bottles-input">(<b>Stock: </b>{StockTBottles})</label>
                            <div className='product-list-item__qty-row'>
                                <input type="button" value="-" className="button-minus no_print"
                                       onClick={()=>setBottles(bottles>0&&bottles-1)} data-field="quantity"
                                />
                                <input type="number" id="stock-bottles-input" name="stock-bottles-input" value={bottles} placeholder={'0'}
                                       onChange={(e) => setBottles(+e.target.value<0?'':+e.target.value)} max={StockTBottles}
                                />
                                {bottles<StockTBottles &&
                                    <input type="button" value="+" className="button-plus no_print"
                                           onClick={()=>setBottles(+bottles+1)} data-field="quantity"
                                    />}
                                {bottles>=StockTBottles &&
                                    <input type="button" value="+" className="button-plus no_print"
                                           data-field="quantity"
                                    />}
                            </div>
                        </div>
                        }
                    </div>
                    <div className="basket-list-item_right__bottom__right">

                        <div className="basket-list-item_inner update-cart">
                            <button className={`update-cart-btn update-cart-basket-list-table ${updateClass}`} onClick={()=> addToCartHandler()}>
                                <i className="fa fa-refresh"></i>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}