import {lazy, Suspense} from "react";

export const Fallback = Suspense;

// Propsect Form
export const Prospects = lazy(() => import("./Prospects"));

// My Rep Modal
export const MyRep = lazy(() => import("./MyRep"));

// Payment Gateways
export const Stripe = lazy(() => import("./payment/Stripe"));
export const Credit = lazy(() => import("./payment/Credit"));