import '../sass/components/footer.scss';
import oiLogo from "../assets/open-imagination.png";
import vintnerLogo from "../assets/Vintner-Logo.png";
import React from "react";
import {Link} from "react-router-dom";

function Footer () {
    return (
        <footer className={'footer'}>
            <div className="container">
                <div className="footer-top">
                    <a href="https://www.openimagination.co.uk/" target="_blank" className="footer-logo footer-logo_left">
                        <img src={oiLogo} alt="open-imagination logo" />
                    </a>
                    <a href="http://www.vintner.co.uk/" target="_blank" className="footer-logo footer-logo_right">
                        <img src={vintnerLogo} alt="vintner logo" />
                    </a>
                </div>
                <div className="footer-bottom">
                    <div className="footer-bottom_inner_top">
                        <p className={'copyright'}>© {new Date().getFullYear()}. All rights reserved.</p>
                    </div>

                    <div className="footer-bottom_inner_bottom">
                        <Link className="footer-link footer-privacy-link" to="/privacy">Privacy</Link>
                        <Link className="footer-link footer-terms-conditions-link" to="/terms-conditions">Terms & Conditions</Link>
                    </div>
                </div>

            </div>
        </footer>
    )
}
export default Footer