import React, {Fragment} from "react";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchOneProduct} from "../apiRequests/api";
import Loading from "./Loading";
import wineWhite from "../assets/Wine-white.png";
import wineWhiteGrey from "../assets/Wine-white-grey.png";
import wineRed from "../assets/Wine-red.png";
import "../sass/components/product-detail.scss";
import "../sass/components/update-cart-btn.scss";
import {useAuth0} from "@auth0/auth0-react";
import {updateCart} from "../utils/cartTools";
import {useDispatch, useSelector} from "react-redux";
import {productFields} from "../settings";
import TastingNotes from "./TastingNotes";
import {BlobProvider} from '@react-pdf/renderer';
import printIcon from "../assets/print_icon.svg"

export default function ProdItem (props) {

    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    const [cases, setCases] = useState(props.cases?props.cases:'')
    const [bottles, setBottles] = useState(props.bottles?props.bottles:'')
    const [product, setProduct] = useState({})
    var StockTBottles = +product.StockCases * +product.CaseSize + +product.StockBottles
    const [isLoading, setLoading] = useState(true)
    const [cartUpdateStyles, setCartUpdateStyles] = useState('')
    const [cartAdd, setCartAdd] = useState('')
    const {id} = useParams()
    var prodCode = {
        ProductCode: id?id:props.id
    }
    const {getIdTokenClaims} = useAuth0();
    // change data regarding to product depending on page
    async function loadProduct() {
        if (id || props.id) {
            const token = await getIdTokenClaims()
            const singleProduct = (await fetchOneProduct(prodCode, token.__raw)).mainResult
            setProduct(singleProduct)
            setLoading(false)
            if (!props.id) cart.items.forEach(el => {
                if (el.Code == singleProduct.Code) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }
            })
        } else {
            setProduct(props.product)
            cart.items.forEach(el => {
                if (el.Code == props.product.Code) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }
            })
        }
    }

    // Build arrays for Notes and Icon groups

    // Notes
    const notesGroup = [];
    const regex = /(<([^>]+)>)/ig;
    if (product.Notes1) {
        notesGroup.push(product.Notes1.replace(regex, ''));
    }
    if (product.Notes2) {
        notesGroup.push(product.Notes2.replace(regex, ''));
    }
    if (product.Notes3) {
        notesGroup.push(product.Notes3.replace(regex, ''));
    }
    if (product.Notes4) {
        notesGroup.push(product.Notes4.replace(regex, ''));
    }

    // Wine Making Notes
    var wineMakingNotes = "";
    if(product.WineMakingNotes) {
        wineMakingNotes = product.WineMakingNotes.replace(regex, '');
    }
    // Type and Style
    const locationGroup = [];
    if (product.CountryDescription) {
        locationGroup.push(toTitles(product.CountryDescription));
    }
    if (product.CustomListItem6) {
        locationGroup.push(product.CustomListItem6);
    }
    if (product.Appellation && product.Appellation !== 0) {
        locationGroup.push(product.Appellation);
    }


    // Type and Style
    const typeGroup = [];
    if (product.Type && product.Type !== 'Not Set') {
        typeGroup.push(product.Type);
    }
    if (product.Style && product.Style !== 'Not Set') {
        typeGroup.push(product.Style);
    }
    if (product.ClosureStyle) {
        typeGroup.push(product.ClosureStyle);
    }

    // Organic / Biodynamic
    const organicGroup = [];
    if (product.OrganicBiodynamic == 'Both' || product.OrganicBiodynamic == 'Organic') {
        organicGroup.push('Organic');
    }
    const bioGroup = [];
    if (product.OrganicBiodynamic == 'Both' || product.OrganicBiodynamic == 'Biodynamic') {
        bioGroup.push('Biodynamic');
    }

    // Suitable for
    // TODO - In React can we loop through product.Allergen_SuitableFor* and condense this function?
    const suitableGroup = [];
    if (product.Allergen_SuitableForVegetarian > 0) {
        suitableGroup.push('Vegetarian');
    }
    if (product.Allergen_SuitableForVegan > 0) {
        suitableGroup.push('Vegan');
    }
    if (product.Allergen_SuitableForGlutenFreeDiet > 0) {
        suitableGroup.push('Gluten Free');
    }
    if (product.Allergen_SuitableForEggIntoleranceDiet > 0) {
        suitableGroup.push('Egg Intolerance');
    }
    if (product.Allergen_SuitableForLactoseIntoleranceDiet > 0) {
        suitableGroup.push('Lactose Intolerance');
    }
    if (product.Allergen_SuitableForLowFatDiet > 0) {
        suitableGroup.push('Low Fat Diet');
    }

    // Allergens
    // TODO - In React can we loop through product.Allergen_* and condense this function?
    // TODO - or do we manage this in the PHP backend controllers and send this array to React?
    const allergenGroup = [];
    if (product.Allergen_Sulphites > 0) {
        allergenGroup.push('Sulphites');
    }
    if (product.Allergen_Milk > 0) {
        allergenGroup.push('Milk');
    }
    if (product.Allergen_Egg > 0) {
        allergenGroup.push('Egg');
    }
    if (product.Allergen_Fish > 0) {
        allergenGroup.push('Nuts');
    }
    if (product.Allergen_Cereals > 0) {
        allergenGroup.push('Cereals');
    }
    if (product.Allergen_Nuts > 0) {
        allergenGroup.push('Nuts');
    }
    if (product.Allergen_Crustaceans > 0) {
        allergenGroup.push('Crustaceans');
    }
    if (product.Allergen_Molluscs > 0) {
        allergenGroup.push('Molluscs');
    }
    if (product.Allergen_Peanuts > 0) {
        allergenGroup.push('Peanuts');
    }
    if (product.Allergen_Soybeans > 0) {
        allergenGroup.push('Soybeans');
    }
    if (product.Allergen_Mustard > 0) {
        allergenGroup.push('Mustard');
    }
    if (product.Allergen_Sesame > 0) {
        allergenGroup.push('Sesame');
    }
    if (product.Allergen_Lupin > 0) {
        allergenGroup.push('Lupin');
    }

    // Food Suggestion
    // TODO - In React can we loop through product.Allergen_* and condense this function?
    // TODO - or do we manage this in the PHP backend controllers and send this array to React?
    const suggestionGroup = [];
    if (product.FoodSuggestion1 !== 'Not Set') {
        suggestionGroup.push(product.FoodSuggestion1);
    }
    if (product.FoodSuggestion2 !== 'Not Set') {
        suggestionGroup.push(product.FoodSuggestion2);
    }
    if (product.FoodSuggestion3 !== 'Not Set') {
        suggestionGroup.push(product.FoodSuggestion3);
    }
    if (product.FoodSuggestion4 !== 'Not Set') {
        suggestionGroup.push(product.FoodSuggestion4);
    }
    if (product.FoodSuggestion5 !== 'Not Set') {
        suggestionGroup.push(product.FoodSuggestion5);
    }

    function addToCartHandler() {
        let currentProduct
        if (id || props.id) {
            currentProduct = product
        } else {
            currentProduct = props.product
        }

        if (cases > 0 || bottles > 0) {
            let rest = bottles % currentProduct.CaseSize
            let newCases = +cases
            let newBottles = +bottles

            if (newBottles > StockTBottles) {
                setCases(newCases)
                setBottles(StockTBottles)
                newBottles = StockTBottles
            } else {
                setCases(newCases)
                setBottles(newBottles)
            }

            const newCart = updateCart(currentProduct, cart, 'ADD', newCases, newBottles)
            dispatch({type: "UPDATE_CART", payload: newCart})
            setCartAdd('added')
            setTimeout(() => setCartAdd(''), 3000);
            setCartUpdateStyles('refreshed')
            setTimeout(() => setCartUpdateStyles(''), 500);
        } else {
            const newCart = updateCart(currentProduct, cart, "REMOVE")
            dispatch({type: "UPDATE_CART", payload: newCart})
        }
    }

    useEffect(() => {
        loadProduct()
    }, [])

    if (isLoading && id) {
        return <Loading/>;
    }

    // Get correct image for product.
    var image = (product.Type == "White") ? wineWhite
            : (product.Type == "Red") ? wineRed : wineWhiteGrey;

    if (process.env.REACT_APP_UNIT_FLAG !== "1") {
        var Unit = product.CaseSize;
    } else {
        var Unit = "1";
    }

    function toTitles(s) {
        return s.replace(/\w\S*/g, function (t) {
            return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
        });
    }

    return (
        <Fragment>

            <div className="product-details">
                <div className="product-flex">
                    <div className="product-img">
                        <img
                            src={image}
                            alt="Profile"
                            className="nav-user-profile rounded-circle"
                        />
                        <div className="download-icon-cont">

                                <div className="download-icon">
                                    <i class="fa-3x fa-thin fa-download"></i>
                                </div>
                            <BlobProvider document={<TastingNotes product={product} image={image} suggestionGroup={suggestionGroup} suitableGroup={suitableGroup} allergenGroup={allergenGroup} notesGroup={notesGroup} wineMakingNotes={wineMakingNotes}/>}>
                                {({ url }) => (
                                    <a href={url} target="_blank">Download tasting notes</a>
                                )}
                            </BlobProvider>
                        </div>
                    </div>
                    <div className="product-info">
                        <div className="product-list-item_right">
                            {product.Description != "" &&
                                <div className='product-list-item-title-link-wrapper'>
                                    <Link className='product-list-item-title-link'
                                          to={`/product-detail/${product.Code}`}>
                                        <h2 className='product-list-item-title'>{product.Description}</h2></Link>
                                </div>
                            }

                            <div className="product-list-item_right__top">
                                {/*    {product.CaseSize != "" && product.UnitSize != ""  &&*/}
                                {/*    <div className="product-list-item_inner unit-case-size">*/}
                                {/*        <i className="fas fa-icon fa-shopping-basket"></i>*/}
                                {/*        <p>{product.CaseSize}x {product.UnitSize}</p>*/}
                                {/*    </div>*/}
                                {/*    }*/}
                                {/*    {product.Code != "" &&*/}
                                {/*    <div className="product-list-item_inner bin-no">*/}
                                {/*        <i className="fas fa-icon fa-hashtag"></i>*/}
                                {/*        <p>{product.Code}</p>*/}
                                {/*    </div>*/}
                                {/*    }*/}
                                {/*    {product.Vintage?.toString().replace(/\s/g,"") != "" &&*/}
                                {/*    <div className="product-list-item_inner">*/}
                                {/*        <i className="fas fa-icon fa-clock-o"></i>*/}
                                {/*        <p>{product.Vintage}</p>*/}
                                {/*    </div>*/}
                                {/*    }*/}

                            </div>
                        </div>
                        {productFields.map((el, index) => el.name && !el.combined ? (
                                <div key={index} className="product-list-item_inner">
                                    <i className={`fas fa-icon ${el.iconClass}`}></i>
                                    <p>{product[el.name]}</p>
                                </div>
                            ) : (
                                <div key={index} className="product-list-item_inner">
                                    <i className={`fas fa-icon ${el.iconClass}`}></i>
                                    <p>
                                        {el.combined.map((combinedEl, index) => (
                                            <span
                                                key={index}>{product[combinedEl.name]}{index < el.combined.length - 1 && el.separator}</span>
                                        ))}
                                    </p>
                                </div>
                            )
                        )}
                        <p className="product-price">{window.currency}{(Math.round((product.BottleNett * 1000) / 10) / 100).toFixed(2)}</p>
                        <div className="product-stats">
                            <div className="product-list-item_inner input stock">
                                <label className="product-list-item-input-label" htmlFor="stock-cases-input"><b>Bottles
                                    in
                                    stock: </b>{product.StockCases}</label>
                            </div>
                            <div className="product-list-item_inner input quantity">
                                {process.env.REACT_APP_UNIT_FLAG !== "1" &&
                                    <div className='product-list-item__qty-row'>
                                        <input type="button" value="-" className="button-minus no_print"
                                               onClick={() => setCases(cases > 0 && cases - 1)} data-field="quantity"
                                        />
                                        <input type="number" id="stock-cases-input" name="stock-cases-input"
                                               autoComplete="off"
                                               max="1" value={cases} placeholder={'0'}
                                               onChange={(e) => setCases(+e.target.value < 0 ? '' : +e.target.value)}
                                        />
                                        <input type="button" value="+" className="button-plus no_print"
                                               onClick={() => setCases(+cases + 1)} data-field="quantity"
                                        />
                                    </div>
                                }
                                {process.env.REACT_APP_UNIT_FLAG !== "2" &&
                                    <div className='product-list-item__qty-row'>
                                        <input type="button" value="-" className="button-minus no_print"
                                               onClick={()=>setBottles(bottles>0&&bottles-1)} data-field="quantity"
                                        />
                                        <input type="number" id="stock-bottles-input" name="stock-bottles-input" autoComplete="off" value={bottles} placeholder={'0'}
                                               onChange={(e) => setBottles(+e.target.value<0?'':+e.target.value)}  min="0" max={StockTBottles}
                                        />
                                        {bottles<StockTBottles &&
                                            <input type="button" value="+" className="button-plus no_print"
                                                   onClick={()=>setBottles(+bottles+1)} data-field="quantity"
                                            />}
                                        {bottles>=StockTBottles &&
                                            <input type="button" value="+" className="button-plus no_print"
                                                   data-field="quantity"
                                            />}
                                    </div>
                                }
                            </div>

                            <div className="product-list-item_right__bottom__right">
                                <div className="product-list-item_inner update-cart">
                                    {StockTBottles > 0 &&
                                        <button className={`update-cart-btn update-cart-product-list-table ${cartUpdateStyles}`} onClick={()=> addToCartHandler()}>
                                            <p>Add to Cart</p>
                                            <i className="fas fa-icon fa-shopping-cart"></i>
                                        </button>}
                                    {StockTBottles < 1 &&
                                        <button className={`update-cart-btn disabled update-cart-product-list-table`} disabled>
                                            <p>Out of Stock</p>
                                            <i className="fas fa-icon fa-shopping-cart"></i>
                                        </button>}
                                    <div className={`add-to-cart ${cartAdd}`}>{product.Description} added to basket</div>

                                    {/*<Popup className='product_popup' onOpen={()=>addToCartHandler()} trigger={(*/}
                                    {/*    <button className={`update-cart-btn update-cart-product-list-table ${cartUpdateStyles}`}>*/}
                                    {/*        <p>Add to Cart</p>*/}
                                    {/*        <i className="fas fa-icon fa-shopping-cart"></i>*/}
                                    {/*    </button>*/}
                                    {/*)} position="bottom center">*/}
                                    {/*    */}
                                    {/*</Popup>*/}
                                </div>
                            </div>
                        </div>
                        <div className="product-description">
                            {notesGroup.length ? (
                                notesGroup.map((item) => (
                                    <p>{item}</p>
                                ))) : ""}
                            <p>{wineMakingNotes}</p>
                            <div className="product-tags">
                                {product.CustomListItem6 &&
                                    <div className="product-tag">{product.CustomListItem6}</div>
                                }
                                {product.Type &&
                                    <div className="product-tag">{product.Type}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <div className="product-info-circles">
                    {locationGroup.length ? (
                        <div className="info-circle">
                            <div className="circle origin"></div>
                            {locationGroup.map((item) => (
                                <p>{item}</p>
                            ))}
                        </div>) : ""}

                    {typeGroup.length ? (
                        <div className="info-circle">
                            <div className="circle screw-cap"></div>
                            {typeGroup.map((item) => (
                                <p>{item}</p>
                            ))}
                        </div>) : ""}

                    {product.AlcoholicPercentage ? (
                        <div className="info-circle">
                            <div className="circle abv"></div>
                            <p>{product.AlcoholicPercentage}%</p>
                        </div>) : ""}


                    {organicGroup.length ? (
                        <div className="info-circle">
                            <div className="circle organic"></div>
                            {organicGroup.map((item) => (
                                <p>{item}</p>
                            ))}
                        </div>) : ""}

                    {bioGroup.length ? (
                        <div className="info-circle">
                            <div className="circle bio"></div>
                            {bioGroup.map((item) => (
                                <p>{item}</p>
                            ))}
                        </div>) : ""}

                    {product.Vintage.length > 1 &&
                        <div className="info-circle">
                            <div className="circle vintage"></div>
                            <p>{product.Vintage}</p>
                        </div>
                    }

                    {allergenGroup.length ? (
                        <div className="info-circle">
                            <div className="circle "><i className="fa-3x fa-thin fa-exclama"></i></div>
                            {allergenGroup.map((item) => (
                                <p>{item}</p>
                            ))}
                        </div>) : ""}

                    {suitableGroup.length ? (
                        <div className="info-circle">
                            <div className="circle vegan"></div>
                            {suitableGroup.map((item) => (
                                <p>{item}</p>
                            ))}
                        </div>) : ""}

                    {suggestionGroup.length ? (
                        <div className="info-circle">
                            <div className="circle"><i className="fa-3x fa-thin fa-fork-knife"></i></div>
                            {suggestionGroup.map((item) => (
                                <p>{item}</p>
                            ))}
                        </div>) : ""}

                    {product.ExciseDescription &&
                        <div className="info-circle">
                            <div className="circle"><i class="fa-3x fa-thin fa-money-check-dollar"></i></div>
                            <p>{product.ExciseDescription}</p>
                        </div>
                    }

                    {product.Winemaker.Name &&
                        <div className="info-circle">
                            <div className="circle winemaker"></div>
                            <p>{product.Winemaker.Name}</p>
                        </div>
                    }

                </div>
            </div>
        </Fragment>
                )
            }


