import '../sass/components/privacy-terms-conditions.scss';

function Privacy() {

    return (
        <div className="page privacy-page">
            <h1 className='privacy-page-title'>Privacy policy</h1>

            <p>What Information Do We Collect?<br/>
                We collect information from you when you register on our site, place an order, subscribe to our
                newsletter or fill out a form.</p>

            <p>When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail
                address, mailing address, phone number or credit card information. You may, however, visit our site
                anonymously.</p>

            <p>What Do We Use Your Information For?<br/>
                Any of the information we collect from you may be used in one of the following ways:</p>

            <p>To personalize your experience (your information helps us to better respond to your individual needs)</p>

            <p>To improve our website (we continually strive to improve our website offerings based on the information
                and feedback we receive from you)</p>

            <p>To improve customer service (your information helps us to more effectively respond to your customer
                service requests and support needs)</p>

            <p>To process transactions Your information, whether public or private, will not be sold, exchanged,
                transferred, or given to any other company for any reason whatsoever, without your consent, other than
                for the express purpose of delivering the purchased product or service requested.</p>

            <p>To administer a contest, promotion, survey or other site feature</p>

            <p>To send periodic emails (The email address you provide for order processing, may be used to send you
                information and updates pertaining to your order, in addition to receiving occasional company news,
                updates, related product or service information, etc.Note: If at any time you would like to unsubscribe
                from receiving future emails, we include detailed unsubscribe instructions at the bottom of each
                email)</p>

            <p>How Do We Protect Your Information?<br/>
                We implement a variety of security measures to maintain the safety of your personal information when you
                place an order or enter, submit, or access your personal information.</p>

            <p>We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure
                Socket Layer (SSL) technology and then encrypted into our Database to be only accessed by those
                authorized with special access rights to our systems, and are required to keep the information
                confidential.</p>

            <p>After a transaction, your private information (credit cards, social security numbers, financials, etc.)
                will not be stored on our servers.</p>

            <p>Do We Use Cookies?<br/>
                Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive
                through your Web browser (if you allow) that enables the sites or service providers systems to recognize
                your browser and capture and remember certain information)</p>

            <p>We use cookies to help us remember and process the items in your shopping cart, understand and save your
                preferences for future visits and compile aggregate data about site traffic and site interaction so that
                we can offer better site experiences and tools in the future.</p>

            <p>Do We Disclose Any Information To Outside Parties?<br/>
                We do not sell, trade, or otherwise transfer to outside parties your personally identifiable
                information. This does not include trusted third parties who assist us in operating our website,
                conducting our business, or servicing you, so long as those parties agree to keep this information
                confidential. We may also release your information when we believe release is appropriate to comply with
                the law, enforce our site policies, or protect ours or others rights, property, or safety. However,
                non-personally identifiable visitor information may be provided to other parties for marketing,
                advertising, or other uses.</p>

            <p>Online Privacy Policy Only<br/>
                This online privacy policy applies only to information collected through our website and not to
                information collected offline.</p>

            <p>Your Consent<br/>
                By using our site, you consent to our websites privacy policy.</p>

            <p>Changes To Our Privacy Policy<br/>
                If we decide to change our privacy policy, we will post those changes on this page.</p>

            <p>This policy was last updated September 2017</p>

            <p>Contacting Us<br/>
                By Post:<br/>
                Wines Direct49 Lough Sheever Corporate Park, Mullingar, Co.Westmeath, Ireland</p>

            <p>By Telephone:<br/>
                +353 44 934 0634</p>

            <p>By LoCall:<br/>
                0818 579 579</p>

            <p>By Email:<br/>
                online@winesdirect.ie</p>


        </div>
    )
}

export default Privacy