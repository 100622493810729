import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {fetchDeliveryAddresses, fetchTransactions} from "../apiRequests/api";
import {fetchCustomer} from "../apiRequests/api";
import Loading from "./Loading";


import "../sass/components/account.scss"
import DateInput from "./DateInput";
import {Link} from "react-router-dom";

import {refactorDateUs,refactorDate} from "../utils/dateRefactor";

function Account () {
    const [fields, setFields] = useState({startDate:'01/01/2017',endDate:refactorDateUs(new Date)})
    const [errors, setErrors] = useState({startDate: false, endDate: false})
    const [transactions, setTransactions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const {getIdTokenClaims} = useAuth0()
    const [customer, setCustomer] = useState([])
    async function loadCustomer() {
        const token = await getIdTokenClaims()
        const data = await fetchCustomer(token.__raw)
        if (!data.error) {
            setCustomer(data.CreditLimit)
        }
        setCustomer(data)
        setIsLoading(false)
        console.log(data.error)
    }
    async function loadAllTransactions () {
        setIsLoading(true)
        let fetchParameters = { "TransactionsByDirection" :  "Ascending", "TransactionDateStart": refactorDate(fields.startDate), "TransactionDateEnd": refactorDate(fields.endDate) }
        const token = await getIdTokenClaims()
        const data = await fetchTransactions(fetchParameters, token.__raw)
        if(data.error){
            setTransactions(data)
        } else {
            setTransactions(data.mainResult)
        }
        setIsLoading(false)
    }
    function getTransactionsHandler () {
        let isValid = true
        var date_regex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
        const newErorrs = {...errors}
        let startDate = refactorDateUs(fields.startDate)
        let endDate = refactorDateUs(fields.endDate)
        for (const [key, value] of Object.entries(fields)) {
            if (!(date_regex.test(fields[key]))) {
                newErorrs[key] = true
                isValid = false
            } else {
                newErorrs[key] = false
            }
        }
        isValid = startDate > endDate ? (
            newErorrs["startDate"] = true,
                false
        ):true
        setErrors(newErorrs)
        isValid?loadAllTransactions():alert('Please select correct date')
    }
    function handleChange(field, value) {
        const newFields = {...fields};
        newFields[field] = refactorDateUs(value)
        setFields(newFields);
    }
    useEffect(() => {
        loadCustomer()
        loadAllTransactions()
    },[])
    return (
        <div className="page orders-page account-page">
            <h2 className='page-title'>My Account</h2>
            <div className="orders__search orders__search-account">
                <h2>Transactions</h2>
                     <div className="orders__field datepicker">
                    <p>Order Date Start: </p>
                    <DateInput type={"text"}
                               value={refactorDateUs(fields.startDate)}
                               name={"startDate"}
                               setValue={handleChange}
                               error={errors.startDate}
                    />
                </div>
                <div className="orders__field datepicker">
                    <p>Order Date End:</p>
                    <DateInput type={"text"}
                               value={refactorDateUs(fields.endDate)}
                               name={"endDate"}
                               setValue={handleChange}
                               error={errors.endDate}
                    />
                </div>
                <div className="orders__submit_wrapper">
                    <button className='orders__submit' onClick={() => getTransactionsHandler()}>Get Transactions</button>
                </div>
            </div>

            <div className="orders__wrapper">
                {!transactions.error&&
                <div className="orders__list_header">
                    <div className='orders__items_header_inner orders__items_header-order-no'>Date</div>
                    <div className='orders__items_header_inner orders__items_header-vat'>Order Number</div>
                    <div className='orders__items_header_inner orders__items_header-order-date'>Tran No.</div>
                    <div className='orders__items_header_inner orders__items_header-type'>Type</div>
                    <div className='orders__items_header_inner orders__items_header-invoice'>Total</div>
                    <div className='orders__items_header_inner orders__items_header-reference'>Balance</div>
                    <div className='orders__items_header_inner orders__items_header-nett'>Reference</div>
                </div>
                }
                <Loading isLoading={isLoading}/>
                <div className="orders__list_body">
                {!transactions.error?transactions.map((el, index)=>(
                    <div className="orders__list_row" key={index}>
                        <div className='orders__list_col orders__list_col'>
                            <span className='orders__list_col_label'>Date</span>
                            <p>{refactorDate(el.TransactionDate)}</p>
                        </div>
                        <div className='orders__list_col orders__list_col-order-no'>
                            <span className='orders__list_col_label'>Order Number</span>
                            <Link to={`/order-details/${el.Reference2}`}><p>{el.Reference2}</p></Link>
                        </div>
                        <div className='orders__list_col orders__list_col'>
                            <span className='orders__list_col_label'>Tran No.</span>
                            <p>{el.TransactionNumber}</p>
                        </div>
                        <div className='orders__list_col orders__list_col'>
                            <span className='orders__list_col_label'>Type</span>
                            <p>{el.Type}</p>
                        </div>
                        <div className='orders__list_col orders__list_col'>
                            <span className='orders__list_col_label'>Total</span>
                            <p>{parseInt(el.Total).toFixed(2)}</p>
                        </div>
                        <div className='orders__list_col orders__list_col'>
                            <span className='orders__list_col_label'>Balance</span>
                            <p>{parseInt(el.Balance).toFixed(2)}</p>
                        </div>
                        <div className='orders__list_col orders__list_col'>
                            <span className='orders__list_col_label'>Reference</span>
                            <p>{el.Reference1}</p>
                        </div>
                    </div>
                )):(
                    <div className="error-message">{transactions.error}</div>
                )}
                </div>
            </div>
        </div>
    )
}
export default Account