import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";

import '../sass/components/basket.scss';
import '../sass/components/basket-items.scss';
import closeIcon from "../assets/close-white.svg";

import BasketItem from "./BasketItem";
import {Link} from "react-router-dom";
import Totals from "./Totals";

function Basket () {
    const [bulkUpdateClass, setBulkUpdate] = useState('')
    const cart = useSelector((state) => state.cart)
    let totalNett = 0
    let totalVAT = 0
    let grandTotal = 0
    const dispatch = useDispatch()

    function bulkUpdate () {
        // TODO needs another decision (now is not enough time)
        var addButtons = document.querySelectorAll('.update-cart-basket-list-table')
        addButtons.forEach(el=> el.click())
        setBulkUpdate('refreshed')
        setTimeout(function(){ setBulkUpdate('') }, 500);
    }
    function clearCart () {
        dispatch({type: "UPDATE_CART", payload: []})
    }
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
    },[cart])
    return (
        <div className="page basket">
            <h2>Basket</h2>
            <div className='basket-items'>
                {cart.items.length>0&&(
                    <div className="basket-items--cart-control">
                        <button onClick={() => clearCart()} className="basket-items--clear-cart">Empty<img alt={'close'} src={closeIcon}/></button>
                        <button onClick={() => bulkUpdate()} className={`basket-items--update-cart ${bulkUpdateClass}`}>Update Cart <i className="fa fa-refresh"></i></button>
                    </div>
                )}

                {cart.items.map((el, index)=>{
                    totalNett+=el.CasePrice*el.CasesQty+el.BottlesQty*el.BottleNett
                    totalVAT+=el.BottleVAT*(el.CasesQty*el.CaseSize+el.BottlesQty)
                    grandTotal = totalNett + totalVAT
                    return (
                        <BasketItem key={index} el={el}/>
                    )
                })}
                {cart.items.length>0 ? (
                    <div className="totals-block-wrapper">
                        <Totals totalNett={totalNett} totalVAT={totalVAT} grandTotal={grandTotal}/>
                        <Link to={"/checkout"} className={"main-button checkout-button"}>Checkout</Link>
                    </div>
                ) : (
                    <div className="flex-col message-empty">
                        <h3>Your basket is empty</h3>
                        <Link to={"/product-list"} className={"main-button checkout-button text-center"}>Return to Product List</Link>
                    </div>
                )}
                {/* <div className='total-block'>
                    <div className='total-col'>
                        {cart.items.length>0?(<>
                            <div className="total-col__row">
                                <h4>Total Nett</h4>
                                <h3>£{numberWithCommas(totalNett.toFixed(2))}</h3>
                            </div>
                            <div className="total-col__row">
                                <h4>Total VAT</h4>
                                <h3>£{numberWithCommas(totalVAT.toFixed(2))}</h3>
                            </div>
                            <div className="total-col__row">
                                <h4>Grand Total</h4>
                                <h3>£{numberWithCommas(grandTotal.toFixed(2))}</h3>
                            </div>
                            <Link to={"/checkout"} className={"main-button checkout-button"}>Checkout</Link>
                        </>):(<>
                            <div className="flex-col message-empty">
                                <h3>Your basket is empty</h3>
                                <Link to={"/product-list"} className={"main-button checkout-button text-center"}>Return to Product List</Link>
                            </div>
                        </>)}
                    </div>
                </div> */}
            </div>
        </div>
    )
}
export default Basket