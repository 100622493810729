import {useEffect, useState} from "react";
import Select from 'react-select'
import "../sass/components/react-select.scss"

export default function Selects (props) {
    const [currentValue, setCurrentValue] = useState(props.value)
    function handleChange(value) {
        !props.order ? props.setPropsValue(value) : props.setPropsValue(value, props.order)
        setCurrentValue(props.options.find(el=>el.value==value))
    }
    useEffect( () => {
        setCurrentValue(props.options.find(el=>el.value==props.value))
    },[props])
    return (
        <Select className='react-select-custom'
                isSearchable={false}
                classNamePrefix='react-select'
                options={props.options}
                onChange={(e) => handleChange(e.value)}
                value={currentValue}
        />
    )
}
