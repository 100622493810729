import React, {Fragment} from "react";

import 'reactjs-popup/dist/index.css';

import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchOneProduct} from "../apiRequests/api";
import Loading from "./Loading";
import wineWhite from "../assets/Wine-white.png";
import wineWhiteGrey from "../assets/Wine-white-grey.png";
import wineRed from "../assets/Wine-red.png";
import "../sass/components/product-item.scss";
import "../sass/components/update-cart-btn.scss";
import {useAuth0} from "@auth0/auth0-react";
import {updateCart} from "../utils/cartTools";
import {useDispatch, useSelector} from "react-redux";
import {productFields} from "../settings";

export default function ProdItem (props) {
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    const [cases, setCases] = useState(props.cases?props.cases:'')
    const [bottles, setBottles] = useState(props.bottles?props.bottles:'')
    const [product, setProduct] = useState({})
    var StockTBottles = +product.StockCases * +product.CaseSize + +product.StockBottles
    const [isLoading, setLoading] = useState(true)
    const [cartUpdateStyles, setCartUpdateStyles] = useState('')
    const [cartAdd, setCartAdd] = useState('')
    const {id} = useParams()
    var prodCode = {
        ProductCode: id?id:props.id
    }
    const {getIdTokenClaims} = useAuth0();
    // change data regarding to product depending on page
    async function loadProduct () {
        if (id || props.id) {
            const token = await getIdTokenClaims()
            const singleProduct = (await fetchOneProduct(prodCode, token.__raw)).mainResult
            setProduct (singleProduct)
            setLoading(false)
            if (!props.id) cart.items.forEach(el=>{
                if (el.Code == singleProduct.Code) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }
            })
        } else {
            setProduct(props.product)
            cart.items.forEach(el=>{
                if (el.Code == props.product.Code) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }
            })
        }
    }

    function handleKeyPress() {

    }

    function addToCartHandler () {
        let currentProduct
        if (id || props.id) {
            currentProduct = product
        } else {
            currentProduct = props.product
        }

        if(cases > 0 || bottles > 0){
            let rest = bottles%currentProduct.CaseSize
            let newCases = +cases
            let newBottles = +bottles

            if(newBottles > StockTBottles) {
                setCases(newCases)
                setBottles(StockTBottles)
                newBottles = StockTBottles
            } else {
                setCases(newCases)
                setBottles(newBottles)
            }
            
            const newCart = updateCart(currentProduct, cart, 'ADD', newCases, newBottles)
            dispatch({type: "UPDATE_CART", payload: newCart})
            setCartAdd('added')
            setTimeout(() => setCartAdd(''), 3000);
            setCartUpdateStyles('refreshed')
            setTimeout(() => setCartUpdateStyles(''), 500);
        } else {
            const newCart = updateCart(currentProduct, cart, "REMOVE")
            dispatch({type: "UPDATE_CART", payload: newCart})
        }
    }

    useEffect( () => {
        loadProduct()
    },[])

    if (isLoading && id) {
        return <Loading />;
    }
    
    // Get correct image for product.
    var image = product.Image1_URL != "" ? process.env.REACT_APP_URL_TO_IMAGES+product.Image1_URL
        : (product.Type == "White") ? wineWhite
        : (product.Type == "Red") ? wineRed : wineWhiteGrey;

    if (process.env.REACT_APP_UNIT_FLAG !== "1") {
        var Unit = product.CaseSize;
    } else {
        var Unit = "1";
    }

    return (
        <Fragment>
            {id&&(<>
                <h2 className="product-details-title">Product Details</h2>
            </>)}
            <div className="product-list-item_wrapper">
                <div className="product-list-item_left">
                    <Link to={`/product-detail/${product.Code}`}>
                        <img
                            src={image}
                            alt="Profile"
                            className="nav-user-profile rounded-circle"
                        />
                    </Link>
                </div>
                <div className="product-list-item_right">
                    {product.Description != "" &&
                    <div className='product-list-item-title-link-wrapper'>
                        <Link className='product-list-item-title-link' to={`/product-detail/${product.Code}`}><h2 className='product-list-item-title'>{product.Description}</h2></Link>
                    </div>
                    }

                    <div className="product-list-item_right__top">
                    {/*    {product.CaseSize != "" && product.UnitSize != ""  &&*/}
                    {/*    <div className="product-list-item_inner unit-case-size">*/}
                    {/*        <i className="fas fa-icon fa-shopping-basket"></i>*/}
                    {/*        <p>{product.CaseSize}x {product.UnitSize}</p>*/}
                    {/*    </div>*/}
                    {/*    }*/}
                    {/*    {product.Code != "" &&*/}
                    {/*    <div className="product-list-item_inner bin-no">*/}
                    {/*        <i className="fas fa-icon fa-hashtag"></i>*/}
                    {/*        <p>{product.Code}</p>*/}
                    {/*    </div>*/}
                    {/*    }*/}
                    {/*    {product.Vintage?.toString().replace(/\s/g,"") != "" &&*/}
                    {/*    <div className="product-list-item_inner">*/}
                    {/*        <i className="fas fa-icon fa-clock-o"></i>*/}
                    {/*        <p>{product.Vintage}</p>*/}
                    {/*    </div>*/}
                    {/*    }*/}
                    {productFields.map((el, index)=> el.name && !el.combined ? (
                            <div key={index} className="product-list-item_inner">
                                <i className={`fas fa-icon ${el.iconClass}`}></i>
                                <p>{product[el.name]}</p>
                            </div>
                        ):(
                            <div key={index} className="product-list-item_inner">
                                <i className={`fas fa-icon ${el.iconClass}`}></i>
                                <p>
                                    {el.combined.map((combinedEl,index)=> (
                                        <span key={index}>{product[combinedEl.name]}{index < el.combined.length-1 && el.separator}</span>
                                    ))}
                                </p>
                            </div>
                        )
                    )}
                    </div>
                    <div className="product-list-item_right__bottom">
                        <div className="product-list-item_right__bottom__left">
                            {process.env.REACT_APP_UNIT_FLAG !== "1" &&
                            <div className="product-list-item_inner input">
                                <h6 className='product-list-item-label cases'>Cases</h6>
                                <label className="product-list-item-input-label" htmlFor="stock-cases-input">(<b>Stock: </b>{product.StockCases})</label>
                                <div className='product-list-item__qty-row'>
                                    <input type="button" value="-" className="button-minus no_print"
                                           onClick={()=>setCases(cases>0&&cases-1)} data-field="quantity"
                                    />
                                    <input type="number"  id="stock-cases-input" name="stock-cases-input" autoComplete="off" max="1" value={cases} placeholder={'0'}
                                           onChange={(e) => setCases(+e.target.value<0?'':+e.target.value)}
                                    />
                                    <input type="button" value="+" className="button-plus no_print"
                                           onClick={()=>setCases(+cases+1)} data-field="quantity"
                                    />
                                </div>
                            </div>
                            }
                            {process.env.REACT_APP_UNIT_FLAG !== "2" &&
                            <div className="product-list-item_inner input">
                                <h6 className='product-list-item-label bottles'>Bottles</h6>
                                <label className="product-list-item-input-label" htmlFor="stock-bottles-input">(<b>Stock: </b>{StockTBottles})</label>
                                <div className='product-list-item__qty-row'>
                                    <input type="button" value="-" className="button-minus no_print"
                                           onClick={()=>setBottles(bottles>0&&bottles-1)} data-field="quantity"
                                    />
                                    <input type="number" id="stock-bottles-input" name="stock-bottles-input" autoComplete="off" value={bottles} placeholder={'0'}
                                       onChange={(e) => setBottles(+e.target.value<0?'':+e.target.value)}  min="0" max={StockTBottles}
                                    />
                                    {bottles<StockTBottles &&
                                    <input type="button" value="+" className="button-plus no_print"
                                           onClick={()=>setBottles(+bottles+1)} data-field="quantity"
                                    />}
                                    {bottles>=StockTBottles &&
                                        <input type="button" value="+" className="button-plus no_print"
                                               data-field="quantity"
                                        />}
                                </div>
                            </div>
                            }
                        </div>
                        <div className="product-list-item_right__bottom__right">
                            {process.env.REACT_APP_UNIT_FLAG !== "1" &&
                            <div className="product-list-item_inner case-nett">
                                <h6 className='product-list-item-label'>Case Nett:</h6>
                                <p>{window.currency}{(Math.round((product.CasePrice * 1000)/10)/100).toFixed(2)}</p>
                            </div>
                            }
                            {process.env.REACT_APP_UNIT_FLAG === "1" &&
                            <div className="product-list-item_inner bottle-nett">
                                <h6 className='product-list-item-label'>Bottle Nett:</h6>
                                <p>{window.currency}{(Math.round((product.BottleNett * 1000)/10)/100).toFixed(2)}</p>
                            </div>
                            }
                            <div className="product-list-item_inner update-cart">
                                {StockTBottles > 0 &&
                                    <button className={`update-cart-btn update-cart-product-list-table ${cartUpdateStyles}`} onClick={()=> addToCartHandler()}>
                                        <p>Add to Cart</p>
                                        <i className="fas fa-icon fa-shopping-cart"></i>
                                    </button>}
                                {StockTBottles < 1 &&
                                    <button className={`update-cart-btn disabled update-cart-product-list-table`} disabled>
                                        <p>Out of Stock</p>
                                        <i className="fas fa-icon fa-shopping-cart"></i>
                                    </button>}
                                <div className={`add-to-cart ${cartAdd}`}>{product.Description} added to basket</div>
                                
                                {/*<Popup className='product_popup' onOpen={()=>addToCartHandler()} trigger={(*/}
                                {/*    <button className={`update-cart-btn update-cart-product-list-table ${cartUpdateStyles}`}>*/}
                                {/*        <p>Add to Cart</p>*/}
                                {/*        <i className="fas fa-icon fa-shopping-cart"></i>*/}
                                {/*    </button>*/}
                                {/*)} position="bottom center">*/}
                                {/*    */}
                                {/*</Popup>*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}