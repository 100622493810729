import {React, useEffect, useState, useRef} from "react";
import Popup from "reactjs-popup";
import logo from "../logo.png";


const Error = (props) => (


    <Popup className={`error ${props.isErrorM ? '' : 'hide'}`} open={props.isErrorM} arrow={false} modal
           nested>
        {close => (<>
            <img src={logo} className="header-logo-img" alt="logo"/>
            <p>Error Submitting Order: {props.Message}</p>
            <p>Please contact us at <a href={'mailto:' + process.env.REACT_APP_EMAIL}>{process.env.REACT_APP_EMAIL}</a></p>
            <div className='close_modal' onClick={() => {
                close();
            }}>&times;</div>
        </>)}
    </Popup>

);

export default Error;
