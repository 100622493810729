/* custom fields for product items on basket, order, product list and product details page */
export const productFields = [
    // for combined values with one icon in one field for example product size - (basket icon)12x75
    {
        combined: [
            {name: "CaseSize"},
            {name: "UnitSize"}
        ],
        iconClass: "fa-light fa-shopping-basket", /* only last class of icon from font-awesome (installed version 4.7) */
        separator: "x" /* use text here, will be used between all combined fields:) */
    },
    {name: "Code", iconClass: "fa-light fa-hashtag"},
    {name: "Vintage", iconClass: "fa-light fa-clock"},
    // {name: "GroupName", iconClass: "fa-flag"},
    // {name: "ClosureStyle", iconClass: "fa-paste"},
    // {name: "SpecialPriceTestFlag", iconClass: "fa-check-circle"}
]
/* selects and inputs for product search, u can change order, or add extra fields according to endpoint available parameters,
require to include key of type of field (input or select) with value 'true' */
export const filterFields = [
    {title: "Description", name: "description", input: true },
    {title: "Colour", name: "colour", select:true, defaultOption: { value: null, label: "All"} },
    {title: "Organic / Biodynamic", name: "organic/biodynamic", select:true, defaultOption: { value: null, label: "All"} },
    {title: "Closure", name: "closure", select:true, defaultOption: { value: null, label: "All"} },
    {title: "Product Code (Real)", name: "product_code", select:true, defaultOption: { value: null, label: "All"} },
]
/* required (minimum) qty of bottles */
export const requiredQtyBottles = 6

// Set closures to override required (minimum) qty of bottles
export const closures = ["KEG", "Bag in Box"]

// choose brand color and below
let brandColor = '#E1625E'
let orderLinkColor = '#E1625E'
let opacityBrand = '#E1625Ea0'
// setting up color variables as sass variables
document.documentElement.style.setProperty("--brandColor", brandColor);
document.documentElement.style.setProperty("--customColor", orderLinkColor);
document.documentElement.style.setProperty("--opacityBrand", opacityBrand);
