import {React, useEffect, useRef, useState} from "react";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import "../sass/components/checkout.scss"
import {fetchDeliveryAddresses, fetchStripeCheckoutSession, fetchSubmitOrder} from "../apiRequests/api";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Loading from "./Loading";
import Error from "./Error";
import {closures, requiredQtyBottles} from "../settings";
import logo from "../logo.png";
import * as Modules from "./Modules";


function Checkout() {
    const [delivery, setDelivery] = useState([])
    const [deliveryIndex, setDeliveryIndex] = useState(0)
    const [customerRef, setCustomerRef] = useState('')
    const [deliveryNotes, setDeliveryNotes] = useState('')
    const [isDeliveryLoading, setIsLoading] = useState(true)
    const [isErrorM, setIsErrorM] = useState(false)
    const [Message, setMessage] = useState('')
    const [OrderN, setOrderN] = useState('')
    const {getIdTokenClaims} = useAuth0()
    const [isLoading, setLoading] = useState(false)

    const cart = useSelector((state) => state.cart)

    let totalBottles = 0
    cart.items && cart.items.forEach(el => {
        totalBottles += (el.CaseSize * el.CasesQty) + el.BottlesQty
    })


    let totalKegs = 0
    cart.items && cart.items.forEach(el => {
        closures.forEach(closure => {
            if (el.ClosureStyle == closure) {
                totalKegs += 1
            }
        })
    })
    let btnCreditRef = useRef();
    let btnCardRef = useRef();
    let history = useHistory()
    const dispatch = useDispatch()

    function onChangeHandler(value) {
        setDeliveryIndex(value)
    }

    async function loadDeliveryAddresses() {
        const token = await getIdTokenClaims()
        const data = await fetchDeliveryAddresses(token.__raw)
        if (!data.error) {
            setDeliveryNotes(data[deliveryIndex].DeliveryNotes1)
        }
        setDelivery(data)
        setIsLoading(false)
        console.log(data.error)
    }

    useEffect(() => {
        setLoading(false)
        loadDeliveryAddresses()
        totalBottles = 0
    }, [])
    return (
        <div className="page checkout-page">
            <h2 className='page-title'>Checkout</h2>
            <div className="checkout__form">
                <div className="checkout__first_step">
                    <Loading isLoading={isDeliveryLoading}/>
                    <div className="checkout__delivery_list">
                        {!delivery.error ? delivery.map((el, index) => (
                            <div className="checkout__delivery_item" key={index}>
                                <input type="radio"
                                       id={'delivery-' + (index + 1)}
                                       value={index} name="delivery"
                                       checked={deliveryIndex == index}
                                       onChange={(e) => onChangeHandler(e.target.value)}
                                />
                                <label htmlFor={'delivery-' + (index + 1)} className="checkout__delivery_info">
                                    <h3 className='checkout__delivery_title checkout__delivery-customer-title'>{el.CustomerAccount}</h3>
                                    <p className='checkout__delivery_field checkout__delivery-name'>{el.Delivery_Name}</p>
                                    {el.Delivery_Address1.length > 1 &&
                                        <p className='checkout__delivery_field checkout__delivery-address'>{el.Delivery_Address1}</p>}
                                    {el.Delivery_Address2.length > 1 &&
                                        <p className='checkout__delivery_field checkout__delivery-address'>{el.Delivery_Address2}</p>}
                                    {el.Delivery_Address3.length > 1 &&
                                        <p className='checkout__delivery_field checkout__delivery-address'>{el.Delivery_Address3}</p>}
                                    {el.Delivery_Address4.length > 1 &&
                                        <p className='checkout__delivery_field checkout__delivery-address'>{el.Delivery_Address4}</p>}
                                    <div className="checkout__details_field">
                                        <h3 className='checkout__delivery_title checkout__delivery-notes-title'>Delivery
                                            Notes:</h3>
                                        {el.DeliveryNotes1.length > 1 &&
                                            <p className='checkout__delivery_field checkout__delivery-notes'>{el.DeliveryNotes1}</p>}
                                        {el.DeliveryNotes2.length > 1 &&
                                            <p className='checkout__delivery_field checkout__delivery-notes'>{el.DeliveryNotes2}</p>}
                                    </div>
                                </label>
                            </div>
                        )) : (
                            <div className="error-message">{delivery.error}</div>
                        )}
                    </div>
                </div>
                <div className="checkout__second_step">
                    <div className="checkout__details">
                        <div className="checkout__details_field">
                            <h3 className="checkout__details_title">Customer Reference / Purchase Order:</h3>
                            <input className="checkout__details_input" type="text" name="customerRef"
                                   value={customerRef} onChange={(e) => setCustomerRef(e.target.value)}/>
                        </div>

                        <div className="checkout__details_field">
                            <h3 className="checkout__details_title">Delivery Notes:</h3>
                            <textarea className="checkout__details_textarea" value={deliveryNotes}
                                      onChange={(e) => setDeliveryNotes(e.target.value)}></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div className="checkout__submit_wrapper flex-def flex-center">
                <Loading isLoading={isLoading}/>
                {
                    requiredQtyBottles <= totalBottles || totalKegs > 0 ? (
                        !delivery.error &&
                        <div>
                            {process.env.REACT_APP_PAYMENT === "1" &&
                                <Modules.Fallback fallback={null}>
                                    <Modules.Stripe btnCardRef={btnCardRef} btnCreditRef={btnCreditRef}
                                                    deliveryIndex={deliveryIndex} customerRef={customerRef}
                                                    deliveryNotes={deliveryNotes} cart={cart} setLoading={setLoading}
                                                    getIdTokenClaims={getIdTokenClaims} setIsErrorM={setIsErrorM}
                                                    setMessage={setMessage} setOrderN={setOrderN} dispatch={dispatch}
                                                    delivery={delivery} history={history}/>
                                </Modules.Fallback>
                            }

                            {process.env.REACT_APP_CREDIT === "1" &&
                                <Modules.Fallback fallback={null}>
                                    <Modules.Credit btnCardRef={btnCardRef} btnCreditRef={btnCreditRef}
                                                    deliveryIndex={deliveryIndex} customerRef={customerRef}
                                                    deliveryNotes={deliveryNotes} cart={cart} setLoading={setLoading}
                                                    getIdTokenClaims={getIdTokenClaims} setIsErrorM={setIsErrorM}
                                                    setMessage={setMessage} setOrderN={setOrderN} dispatch={dispatch}
                                                    delivery={delivery} history={history}/>
                                </Modules.Fallback>
                            }

                        </div>
                    ) : (<>
                        {!delivery.error &&
                            <Popup trigger={<button className="checkout__submit grey">Submit</button>} arrow={false}
                                   modal
                                   nested>
                                {close => (<>
                                    <img src={logo} className="header-logo-img" alt="logo"/>
                                    <p>You may only proceed to the checkout after
                                        purchasing {requiredQtyBottles} bottles</p>
                                    <div className='close_modal' onClick={() => close()}>&times;</div>
                                </>)}
                            </Popup>

                        }
                        <p className='required-qty-msg'>You may only proceed to the checkout after
                            purchasing {requiredQtyBottles} bottles</p>
                    </>)

                }
                <Error isErrorM={isErrorM} Message={Message} OrderNo={OrderN}/>

            </div>
        </div>
    )
}

export default Checkout

