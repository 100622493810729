import '../sass/components/privacy-terms-conditions.scss';

function TermsConditions() {

    return (
        <div className="page terms-conditions-page">
            <h1 className='terms-conditions-page-title'>Terms & Conditions</h1>
            <div className='content'>
                <p>1.1 The products and any other goods delivered by Wines Direct Ltd to the Purchaser shall remain the
                    sole and absolute property of Wines Direct Ltd as legal and equitable owner until such time as all
                    money due to Wines Direct Ltd has been paid by the Purchaser but shall be at the Purchaser's risk
                    from the time of delivery to them.</p>

                <p>1.2 Until delivery to a subsequent purchaser the Purchaser undertakes to store such goods on its
                    premises separately from its own goods or those of any other person and in a manner which makes them
                    readily identifiable as Wines Direct Ltd goods.</p>

                <p>1.3 The Purchaser's right to possession of the goods shall cease if it does anything or fails to do
                    anything which would entitle a receiver to take possession of any assets, or which would entitle any
                    person to present a petition for the winding up of the Purchaser or which would enable the
                    appointment of an official Assignee pursuant to adjudication for bankruptcy of the Purchaser.</p>

                <p>1.4 Wines Direct Ltd may, for the purpose of examination or recovery of its goods enter upon any
                    premises where they are stored or where they are reasonably thought to be stored.</p>

                <p>1.5 Until property in the goods passes from Wines Direct Ltd the entire proceeds of such goods shall
                    be held in trust for Wines Direct Ltd and shall not be mingled with any other money paid into any
                    overdrawn bank account and shall at all times be identifiable as Wines Direct Ltd money.</p>

                <p>1.6 Credit Terms<br/>
                    Credit terms are only offered to customers settling by Direct Debit. Otherwise, cash or bank draft
                    will be required for the total amount due to the vendor at the time of delivery. Payment is due and
                    invoices payable 14 days after the end of month.&nbsp;</p>

                <p>1.7 Prices<br/>
                    Prices are exclusive of VAT, which will be added at prevailing rates. Prices may vary depending upon
                    Market and Currency fluctuations and changes in duty&nbsp;</p>

                <p>1.8 Claims &amp; Returns<br/>
                    Customers are requested to carefully check all goods at the time of delivery. The Delivery Docket
                    should be signed, noting any shortages or breakages. Claims must be made within 7 days of delivery
                    Neither Wines Direct Ltd, nor the supplier is obliged to accept returns unless an error has been
                    committed by the company or supplier as appropriate. A restock fee of 15% of the value of the order
                    applies to all returned orders.</p>

                <p>1.9 Force Majeure<br/>
                    Wines Direct Ltd will not be liable for failure to meet agreed obligations due to prevailing
                    circumstances beyond its reasonable control.</p>

                <p>Application<br/>
                    These Terms and Conditions shall apply to all sales of goods by the vendor and placing an order
                    shall be deemed to be acceptance by the buyer of these Terms and Conditions</p>
            </div>
        </div>
    )
}
export default TermsConditions