import React from "react";
import loading from "../assets/loading.svg";

const Loading = (props) => (
  <div className={`spinner ${props.isLoading ? '' : 'hide'}`}>
    <img src={loading} alt="Loading" />
  </div>
);

export default Loading;
