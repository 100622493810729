
import '../sass/components/login.scss'
import {useAuth0} from "@auth0/auth0-react";
import logo from "../logo.png";
import {Link} from "react-router-dom";

function Login () {

    const { loginWithRedirect } = useAuth0();
    return (
        <div className="page login-page">
            <Link to='/' className="login-logo"><img src={logo} className="login-logo-img" alt="logo" /></Link>
            <h6 className='login-page-title'>Welcome to the Wines Direct Wholesale Ordering System<br />By Open Imagination</h6>
            <button className='login-btn' onClick={ () => loginWithRedirect()}>Log in</button>
            {process.env.REACT_APP_PROSPECTS === "1" &&
                <Link className="trade-account" to='/trade-account'>Apply for a Trade Account</Link>
            }
        </div>
    )
}
export default Login